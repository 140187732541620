export function timeSince(date) {
    var seconds = Math.floor((new Date() - new Date(date)) / 1000);
    var interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return interval + ' years ago';
    }
    if (interval > 0 && interval <= 1) {
      return '1 year ago';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + ' months ago';
    }
    if (interval > 0 && interval <= 1) {
      return '1 month ago';
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + ' days ago';
    }
    if (interval > 0 && interval <= 1) {
      return '1 day ago';
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + ' hours ago';
    }
    if (interval > 0 && interval <= 1) {
      return '1 hour ago';
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + ' minutes ago';
    }
    if (interval > 0 && interval <= 1) {
      return '1 minute ago';
    }
    return 'just now';
  }