export default function VerifyAccess(callback, actions, jsonWebTok) {
  fetch(`${process.env.REACT_APP_API}/verify-access`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `bearer ${jsonWebTok}`,
    },
    body: JSON.stringify({ actions }),
  })
    .then((resp) => resp.json())
    .then((info) => {
      const results = info.data.access.reduce((acc, cur) => {
        let updatedAcc = Object.assign({}, acc, {
          [cur.action]: cur.access_flag,
        });
        return updatedAcc;
      }, {});
      const access = actions.reduce((acc, cur) => {
        const updatedAcc = Object.assign({}, acc);
        updatedAcc[cur] = results[cur] ? results[cur] : 0;
        return updatedAcc;
      }, {});
      callback({ statusCode: 200, access });
    });
}
