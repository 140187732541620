import React, { useReducer, useState, useEffect } from "react";
import { timeSince } from "../utils/timeSince.js";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, AccordionSummary, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

import LambdaFetch from '../functions/FetchFromLambda';

var moment = require("moment-timezone");

export default function Comments(props) {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleComment = async (e) => {
      e.preventDefault()

      if(!e.target.comment.value) {
          props.createSnack(
            'Comment must not be blank',
            'warning',
            3000
          )
          return null
      }
      const {target} =  e
      const comment_string = e.target.comment.value; 
      setSubmitting(true)
      await LambdaFetch(
        'comments',
        'post',
        props.accessToken,
        JSON.stringify({
          action: 'create',
          obj_type: props.objType,
          obj_id: props.objId,
          comment_string: comment_string
        }))
        target.reset(); 

props.getData()
        setSubmitting(false)
  }
  return (
    <div style={{ margin: "1rem auto" }}>
      <Accordion elevation={1}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {`Comments (${props.comments.length})`}
        </AccordionSummary>

        <div style={{ padding: "0 5% 1rem 5%" }}>
          <div style={{ display: "block", margin: "1rem 0.5rem" }}>
            <form onSubmit={handleComment}>
              <textarea
                id="comment"
                placeholder="Join the discussion..."
                rows={3}
                style={{
                  fontSize: "0.875rem",
                  borderRadius: "5px",
                  width: "100%",
                }}
              />
              <div>
                <Button
                  variant="contained"
                  style={{ backgroundColor: isSubmitting ? null : "#E86613", color: "white" }}
                  type="submit"
                  disabled={isSubmitting }
                >
                 {isSubmitting    ? (
              <div>
                <CircularProgress
                  size={24}
                  style={{
                    color: '#545454',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
                Submitting...
              </div>
            ) : (
              'Submit'
            )}
                  <span style={{ margin: "0.3rem" }} />
                  <SendIcon />
                </Button>
              </div>
            </form>
            {props.comments.map((comment) => {
              const diffDate = timeSince(
                moment(
                  moment(new Date(comment.timestamp)).utcOffset("0")._d
                ).format("lll")
              );
              return (
                <div
                  style={{
                    display: "block",
                    margin: "1rem",
                    minHeight: "40px",
                    width: "95%",
                  }}
                  key={comment.comment_id}
                >
                  <Typography
                    style={{
                      display: "inline",
                      color: "#000000",
                      fontWeight: "bold",
                      fontSize: "1em",
                    }}
                  >
                    {comment.user}
                  </Typography>
                  <span style={{ fontSize: "11px", margin: "0 0.2rem" }}>
                    •
                  </span>
                  <Typography
                    style={{
                      display: "inline",
                      color: "#656565",
                      fontWeight: "normal",
                      fontSize: "11px",
                    }}
                  >
                    {diffDate}
                  </Typography>
                  <div style={{ margin: '0.5rem' }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: '#656565',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                      }}
                    >
                      {comment.comment_string}
                    </Typography>
                  </div>
            
                </div>
              );
            })}
          </div>
        </div>
      </Accordion>
    </div>
  );
}
