import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionSummary } from '@material-ui/core'

var moment = require("moment-timezone");

function Audit(props) {
  let reversedActivity = props.activity.slice().reverse()
  return (
    <div>
      <div style={{ margin: "1rem auto" }}>
        
      <Accordion elevation={2}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {`${props.title} (${props.activity.length})`}
          </AccordionSummary>

        {props.appWidth > 600 ? (
          <div>
              <Table style={{ overflowX: "auto" }}>
                <TableBody>
                  {reversedActivity.map((row, index) =>  {
                    return (
                    <TableRow key={row.activity_id}>
                      <TableCell align="left">{row.activity_type}</TableCell>
                      <TableCell align="center" scope="row" style={{minWidth: '120px'}}>
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.activity_string}</TableCell>
                      <TableCell align="center" scope="row" style={{minWidth: '120px'}}>
                        {moment(
                          moment(new Date(row.timestamp)).utcOffset(
                            props.timezone
                          )._d
                        ).format("lll")}
                      </TableCell>
                    </TableRow>
                  )}
                  )}
                </TableBody>
              </Table>
          </div>
        ) : (
          <div>
            {reversedActivity.map((row, index) => {
              return (
                <Card
                  style={{ borderTop: "0.1px solid #545454", borderRadius: 0 }}
                  key={row.activity_id}
                >
                  <CardContent>
                    <div>
                      <span style={{ fontSize: "1.2rem" }}>
                        {row.activity_type}
                      </span>
                      <span
                        style={{
                          float: "right",
                          fontSize: "0.8rem"
                        }}
                      >
                        {moment(
                          moment(new Date(row.timestamp)).utcOffset(
                            props.timezone
                          )._d
                        ).format("lll")}
                      </span>
                      <hr style={{ margin: "0.1rem" }} />
                      <p style={{ margin: "0.8rem" }}>{row.activity_string}</p>
                      <div
                        style={{
                          marginTop: "0.8rem",
                          fontSize: "0.8rem"
                        }}
                      >
                        by: {row.name}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        )}
        </Accordion>
      </div>
    </div>
  );
}

export default Audit;
