import React from 'react';
import { Grid } from '@material-ui/core';
import UserComponent from '../components/UserComponent';

class UserMaintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12}>
            <UserComponent
              access={this.props.access}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              userType="Client"
              client_id={this.props.fetchInitialData.credentials.user.clientId}
              props={this.props}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default UserMaintenance;
