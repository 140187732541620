import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import writeToActivity from '../functions/WriteToActivity';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const timezones = [
  {
    offset: '-10:00',
    label: 'Hawaii Standard Time',
  },
  {
    offset: '-08:00',
    label: 'Alaska Daylight Time',
  },
  {
    offset: '-07:00',
    label: 'Pacific Daylight Time / Mountain Standard Time',
  },
  {
    offset: '-06:00',
    label: 'Mountain Daylight Time',
  },
  {
    offset: '-05:00',
    label: 'Central Daylight Time',
  },
  {
    offset: '-04:00',
    label: 'Eastern Daylight Time',
  },
  {
    offset: '-03:00',
    label: 'Argentina Time',
  },
  {
    offset: '-02:00',
    label: 'Western Greenland Summer Time',
  },
  {
    offset: '-01:00',
    label: 'Cape Verde Time',
  },
  {
    offset: '00:00',
    label: 'Western European Time',
  },
  {
    offset: '+01:00',
    label: 'British Summer Time / British Daylight Time',
  },
  {
    offset: '+02:00',
    label: 'Central European Summer Time',
  },
  {
    offset: '+03:00',
    label: 'Moscow Standard Time',
  },
  {
    offset: '+04:00',
    label: 'Gulf Standard Time',
  },
  {
    offset: '+05:00',
    label: 'Uzbekistan Time',
  },
  {
    offset: '+06:00',
    label: 'Bangladesh Standard Time',
  },
  {
    offset: '+07:00',
    label: 'Western Indonesian Time',
  },
  {
    offset: '+08:00',
    label: 'China Standard Time',
  },
  {
    offset: '+09:00',
    label: 'Japan Standard Time',
  },
  {
    offset: '+10:00',
    label: 'Australian Eastern Standard Time / Eastern Standard Time',
  },
  {
    offset: '+11:00',
    label: 'Solomon Islands Time',
  },
];

class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      tabValue: 0,
      noOfUnpaidInvoices: '',
      amountOfUnpaidInvoices: '',
      approval_status: '',
      isOpen: false,
      action: null,
      isLoading: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleClose() {
    this.setState({ isOpen: false });
  }
  updateAvatarContent = (event) => {
    if (event.target.value.length < 3) {
      this.setState({ editAvatarContent: event.target.value.toUpperCase() });
    }
  };
  handleTimezoneChange = (event) => {
    this.setState({ timezone: event.target.value });
  };
  handleChange = (event, value) => {
    this.setState({ tabValue: value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.action === 'email') {
      if (event.target.newEmail.value !== event.target.newEmailConfirm.value) {
        this.props.createSnack(
          'Emails do not match',
          'warning',
          3000
        );
        this.setState({ isLoading: false });
      } else if (event.target.newEmail.value.length < 1) {
        this.props.createSnack(
          'Must fill in a new Email',
          'warning',
          3000
        );
        this.setState({ isLoading: false });
      } else {
        const jsonVendor = {
          jsonWebTok: this.props.appState.user.jsonWebTok,
          oldUserName: this.props.appState.user.username,
          newUserName: event.target.newEmail.value,
        };
        fetch(`${process.env.REACT_APP_API}/vendor-update-settings`, {
          method: 'post',
          headers: {
            'content-type': 'application/json',
            Authorization: `bearer ${this.props.appState.user.jsonWebTok}`,
          },
          body: JSON.stringify(jsonVendor),
        })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.success) {
              if (
                jsonVendor.oldUserName !== jsonVendor.newUserName &&
                this.props.appState.user.vendorId
              ) {
                const activity = {
                  CLIENT_ID: this.props.appState.user
                    .clientId,
                  OBJ_TYPE: 'VENDOR',
                  OBJ_ID: this.props.appState.user.vendorId,
                  ACTIVITY_TYPE: 'PROFILE UPDATE',
                  ACTIVITY_STRING: `User changed email from ${jsonVendor.oldUserName} to ${jsonVendor.newUserName}`,
                  USER_ID: this.props.appState.user.uuid,
                };
                writeToActivity({ activity }, () => {});
              }
              if (
                jsonVendor.oldUserName !== jsonVendor.newUserName
              ) {
                const activity = {
                  CLIENT_ID: this.props.appState.user
                    .clientId,
                  OBJ_TYPE: 'USER',
                  OBJ_ID: this.props.appState.user.uuid,
                  ACTIVITY_TYPE: 'PROFILE UPDATE',
                  ACTIVITY_STRING: `User changed email from ${jsonVendor.oldUserName} to ${jsonVendor.newUserName}`,
                  USER_ID: this.props.appState.user.uuid,
                };
                writeToActivity({ activity }, () => {});
              }
              var copyOfUserInfo = this.props.appState.user;
              copyOfUserInfo.username = data.data.content.newUserName;
              this.props.changeUserInfo(copyOfUserInfo);
              this.props.createSnack(
                `Your email has been updated to ${data.data.content.newUserName}`,
                'success',
                2000
              );
              this.setState({ isOpen: false, action: null });
            } else if (data.error) {
              if(data.e.errno === 1062) {
                this.props.createSnack(
                  'The email provided is already taken',
                  'error',
                  3000
                );
              } else {
                this.props.createSnack(
                  data.error,
                  'error',
                  3000
                );
              }
            } else {
              this.props.createSnack(
                'There was an error',
                'error',
                3000
              );
            }
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            this.props.createSnack(
              `Error: ${error}`,
              'error',
              3000
            );
          });
      }
    } else if (this.state.action === 'password') {
      if (event.target.newPassword.value.length < 6) {
        this.props.createSnack(
          'The new Password must be atleast 6 characters long',
          'warning',
          3000
        );
        this.setState({ isLoading: false });
      } else if (
        event.target.newPassword.value !== event.target.newPasswordConfirm.value
      ) {
        this.props.createSnack(
          'The passwords do not match',
          'warning',
          3000
        );
        this.setState({ isLoading: false });
      } else {
        const jsonVendor = JSON.stringify({
          jsonWebTok: this.props.appState.user.jsonWebTok,
          oldPassword: event.target.oldPassword.value,
          newPassword: event.target.newPassword.value,
        });
        fetch(`${process.env.REACT_APP_API}/change-user-password`, {
          method: 'post',
          headers: {
            'content-type': 'application/json',
            Authorization: `bearer ${this.props.appState.user.jsonWebTok}`,
          },
          body: jsonVendor,
        })
          .then((resp) => resp.json())
          .then((data) => {
            if(data.error) {
              this.props.createSnack(
                data.error,
                'error',
                4000
              );
              this.setState({ isLoading: false});
              return null
            }
            if (data.success) {
              this.props.createSnack(
                'Your password has been updated',
                'success',
                2000
              );
              this.setState({ isOpen: false, action: null });
            } else {
              this.props.createSnack(
                'There was an error',
                'error',
                3000
              );
            }
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            this.props.createSnack(
              `Error: ${error}`,
              'error',
              3000
            );
          });
      }
    } else if (this.state.action === 'name') {
      const jsonVendor = {
        jsonWebTok: this.props.appState.user.jsonWebTok,
        newName: event.target.newName.value,
      };

      fetch(`${process.env.REACT_APP_API}/vendor-update-settings`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: `bearer ${this.props.appState.user.jsonWebTok}`,
        },
        body: JSON.stringify(jsonVendor),
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            var copyOfUserInfo = this.props.appState.user;
            copyOfUserInfo.name = data.data.content.newName;
            this.props.changeUserInfo(copyOfUserInfo);
            this.props.createSnack(
              'Your name has been updated',
              'success',
              2000
            );

            this.setState({ isOpen: false, action: null });
          }
          this.setState({ isLoading: false });
        })
        .catch((error) => console.log(error));
    }
  }
  render() {
    let title;
    if (this.state.action === 'email') {
      title = 'Update Email';
    } else if (this.state.action === 'password') {
      title = 'Update Password';
    } else if (this.state.action === 'timezone') {
      title = 'Change Timezone';
    } else if (this.state.action === 'avatar') {
      title = 'Change Avatar';
    } else {
      title = 'Update Name';
    }
    let timezoneLabel = '';
    timezones.forEach((zone) => {
      if (
        zone.offset === this.props.appState.user.timezone
      ) {
        timezoneLabel = zone.label;
      }
    });
    const load = this.state.isLoading ? (
      <Fade in={this.state.isLoading} unmountOnExit>
        <CircularProgress
          style={{ marginLeft: '10px' }}
          color="inherit"
          size={15}
        />
      </Fade>
    ) : (
      ''
    );

    const avatarStyle = {
      backgroundColor: '#E86613',
      display: 'inline-flex',
      marginLeft: '0.3rem',
    };
    const editAvatarStyle = {
      backgroundColor: '#E86613',
      marginTop: '15px',
    };
    return (
      <div className="vendorHomeContainer">
        <Dialog
          open={this.state.isOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={this.handleSubmit}>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

            <DialogContent>
              {this.state.action === 'avatar' && (
                <Grid container justify="center" spacing={2}>
                  <Avatar style={editAvatarStyle}>
                    {this.state.editAvatarContent}
                  </Avatar>

                  <Grid item xs={12}>
                    <TextField
                      id="avatarContent"
                      label="Avatar Initals"
                      fullWidth
                      value={this.state.editAvatarContent}
                      onChange={(event) => this.updateAvatarContent(event)}
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                    <SliderPicker
                      color={this.state.editPrimaryAvatarColor}
                      onChangeComplete={color =>
                        this.setState({ editPrimaryAvatarColor: color.hex })
                      }
                    />
                  </Grid> */}
                </Grid>
              )}

              {this.state.action === 'email' && (
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="oldEmail"
                      disabled
                      label="Old Email"
                      fullWidth
                      defaultValue={
                        this.props.appState.user.username
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="newEmail"
                      label="New Email"
                      fullWidth
                      required
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="newEmailConfirm"
                      label="Confirm Email"
                      fullWidth
                      required
                      type="email"
                    />
                  </Grid>
                </Grid>
              )}
              {this.state.action === 'password' && (
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="oldPassword"
                      label="Type Current Password"
                      fullWidth
                      type="password"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="newPassword"
                      label="Create New Password"
                      fullWidth
                      type="password"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="newPasswordConfirm"
                      label="Confirm New Password"
                      fullWidth
                      type="password"
                      required
                    />
                  </Grid>
                </Grid>
              )}
              {this.state.action === 'name' && (
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="oldName"
                      label="Current Name"
                      fullWidth
                      disabled
                      value={this.props.appState.user.name}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="newName"
                      label="Updated Name"
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              )}
              {this.state.action === 'timezone' && (
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Select
                      id="timezone"
                      value={this.state.timezone}
                      onChange={this.handleTimezoneChange}
                      fullWidth
                    >
                      {timezones.map((item) => {
                        return (
                          <MenuItem key={item.offset} value={item.offset}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                style={{ backgroundColor: '#545454', color: 'white' }}
                onClick={() => {
                  this.setState({
                    isOpen: false,
                    action: null,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: 'rgb(232,84,10)',
                  color: 'white',
                }}
                type="submit"
              >
                Submit
                {load}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <div style={{margin: '1rem', fontSize: '20px'}}>
          <Grid container justify="flex-start" spacing={2}>

          {/* <IconButton
              variant="contained"
              className="secondaryVLButton"
              size="medium"
              onClick={() => this.props.history.push('/documents')}
            >
                <ArrowBackIcon/>
       </IconButton> */}
            <Grid item xs={12} sm={12} md={12}>
              <div>
                {`Name:  `} 
                <span className="vendorSettings">
                  {this.props.appState.user.name}
                </span>
                <span
                  className="vendorEdit"
                  onClick={() =>
                    this.setState({
                      isOpen: true,
                      action: 'name',
                    })
                  }
                >
                  edit
                </span>
              </div>
              <div>
              {`Email:  `} 
                <span className="vendorSettings">
                  {this.props.appState.user.username}
                </span>
                <span
                  className="vendorEdit"
                  onClick={() =>
                    this.setState({
                      isOpen: true,
                      action: 'email',
                    })
                  }
                >
                  edit
                </span>
              </div>
              <div>
              {`Password:  `} 
                <span className="vendorSettings">****</span>
                <span
                  className="vendorEdit"
                  onClick={() =>
                    this.setState({
                      isOpen: true,
                      action: 'password',
                    })
                  }
                >
                  edit
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  tabsRoot: {
    color: 'rgb(232,102,19)',
    borderBottom: '0 solid #e8e8e8',
  },
  tabsIndicator: {
    color: 'rgb(232,102,19)',
    backgroundColor: 'rgb(232,102,19)',
  },
  tabRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: 'rgb(232,102,19)',
      opacity: 1,
    },
  },
  avatar: {
    marginTop: 15,
  },
});

export default withStyles(styles)(MyAccount);