export const FieldChangeActivity = (activityObj, callback) => {
  if (activityObj.activity['field_changes']) {
    let jsonChanges = JSON.parse(activityObj.activity['field_changes']);

    if (jsonChanges.length === 0) {
      callback(201);
    } else {
      const initalString = activityObj.activity['activity_string']
      activityObj.activity['activity_string'] = jsonChanges.reduce(
        (string, change, index) => {
          return (
            string +
            `${change.field} from: ${change.before} to: ${change.after}${
              index + 1 === jsonChanges.length ? '' : ', '
            }`
          );
        },
        `${initalString}. `
      );

      fetch(`${process.env.REACT_APP_API}/write-to-activity`, {
        method: 'POST',
        headers: {
          Authorization: `bearer true`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(activityObj),
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            callback(200);
          } else {
            callback(400);
          }
        });
    }
  }
};

export const WriteToActivity = (activityObj, callback) => {
  fetch(`${process.env.REACT_APP_API}/write-to-activity`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `bearer true`,
    },
    body: JSON.stringify(activityObj),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.success) {
        callback(200);
      } else {
        callback(400);
      }
    });
};
