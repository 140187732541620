import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import Documents from "./pages/Documents";
import MyAccount from "./pages/MyAccount";
import AdminSettings from "./pages/AdminSettings";

export default class Routes extends React.Component {
  render() {
    const auth = this.props;
    if (!auth.credentials) return null;
    return (
      <Switch>
        <Route
          path="/documents"
          exact
          render={(props) => {
            if (!auth.credentials.isAuth) {
              return null;
            }
            return (
              <Documents
                {...props}
                fetchInitialData={auth}
                createSnack={auth.createSnack}
              />
            );
          }}
        />
        <Route
          path="/login"
          render={(props) => (
            <Login
              {...props}
              fetchInitialData={auth}
              createSnack={auth.createSnack}
            />
          )}
        />
        <Route
          path="/account"
          render={(props) => (
            <MyAccount
              {...props}
              fetchInitialData={auth}
              createSnack={auth.createSnack}
            />
          )}
        />
        <Route
          path="/settings"
          render={(props) => (
            <AdminSettings {...props} fetchInitialData={auth} />
          )}
        />
        <Redirect from="/" to="/documents" />
      </Switch>
    );
  }
}
