import React from "react";
import LambdaFetch from "./functions/FetchFromLambda";
import Routes from "./Routes";
import AppBar from "./components/AppBar";
import "./App.css";
import { withSnackbar } from "notistack";
import {Typography, Button} from "@material-ui/core";
import { getUserDetails } from "./GraphService";
import { UserAgentApplication } from "msal";
import config from "./Config";
import { withRouter } from 'react-router-dom'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.history  = this.props.history;
    this.UserAgentApplication = new UserAgentApplication({
      auth: {
        authority: config.authority,
        clientId: config.appId,
        redirectUri:
          window.location.host === "localhost:3000"
            ? "http://localhost:3000"
            : "https://app.apix.co",

        postLogoutRedirectUri:
          window.location.host === "localhost:3000"
            ? "http://localhost:3000"
            : "https://app.apix.co",
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateCookie: true,
      },
    });
    const userInfo = this.UserAgentApplication.getAccount();

    this.state = {
      notClient: false,
      isAuth: false,
      appWidth: 1000,
      clientInfo: "",
      user: null,
    };
    if (userInfo) {
      this.getUserProfile();
    } else {
      this.login();
    }
   
  }
  createSnack = (message, type, duration) => {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      variant: type,
      autoHideDuration: duration,
    });
  };
  componentDidMount = async () => {
    this.calcSize();
    window.addEventListener("resize", this.calcSize.bind(this));
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.calcSize.bind(this));
  }
  calcSize = () => {
    this.setState({ appWidth: window.innerWidth });
  };

  getClient = async (tenantId) => {
    const clientInfo = await LambdaFetch(
      "get-client",
      "post",
      null,
      JSON.stringify({ tenantId })
    );
    if (clientInfo.success) {
      const clientData = clientInfo.data.client[0];
      return clientData;
    } else {
      return null;
    }
  };
  getUserProfile = async () => {
    try {
      const accessToken = await this.UserAgentApplication.acquireTokenSilent({
        scopes: config.scopes,
      });
      if (accessToken) {
        const user = await getUserDetails(accessToken);
        const clientData = await this.getClient(accessToken.tenantId);
        if (!clientData) {
          console.log('not valid client')
        this.setState({notClient: true})
          return null;
        } else if (!accessToken.uniqueId) {
          this.logout()
          return null;
        } else {
          this.setState({
            isAuth: true,
            user: {
              uuid: accessToken.uniqueId,
              name: user.displayName,
              username: user.mail || user.userPrincipalName,
              accessToken: accessToken.accessToken,
              clientId: clientData.client_id,
            },
            clientInfo: clientData,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  logout = () => {
    this.UserAgentApplication.logout();
  };
  login = async () => {
    try {
      function authCallback(error, response) {
        console.log(response);
      }
      this.UserAgentApplication.handleRedirectCallback(authCallback);
      await this.UserAgentApplication.loginRedirect({
        scopes: config.scopes,
        prompt: "select_account",
      });
      await this.getUserProfile();
    } catch (err) {
      console.log(err);
      this.setState({
        isAuth: false,
        user: {},
        error: { message: err },
      });
    }
  };

  render() {
    if(this.state.notClient) {
      return (
      <div className="vertical-center horizontal-center">
            <Typography style={{margin: '0.5rem'}} variant="h4">Looks like your company is not signed up with APIX.</Typography>
            <Typography variant="h6">Please contact us <a className="editLink" href="https://www.nogalis.com/">here</a> for more information </Typography>
            <Typography style={{margin: '1rem'}} variant="subtitle2"><a className="editLink" onClick={() => this.logout()}>Click here</a> to sign into a diffrent account.</Typography>

        </div>
      )
    }
    if (this.state.clientInfo === "") {
      return null;
    }
    return (
      <div style={{ maxWidth: "1300px", margin: "0 auto" }}>
        {!!this.state.clientInfo ? (
          <AppBar
            appState={this.state}
            logo={this.state.clientInfo.logo_img}
            logout={this.logout}
          />
        ) : (
          <div style={{ textAlign: "center" }} className="vertical-center">
            <h4>Please Login</h4>
            <Button variant="contained" onClick={() => this.login()}>
              Login Here
            </Button>
          </div>
        )}
        <div
          style={{
            margin: this.state.appWidth < 600 ? "0 0.25rem" : "0 1.5rem",
          }}
        >
          <Routes
            createSnack={this.createSnack}
            credentials={this.state}
            auth={this.authenticate}
            changeUserInfo={this.changeUserInfo}
          />
        </div>
      </div>
    );
  }
}

export default withSnackbar(withRouter(App));
