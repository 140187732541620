export const tabHashes = [
    '#users',
    '#roles'
  ];
  
  export const settingsActions = [
    'settings_user_tab',
    'settings_role_tab',
    'settings_user_add',
    'settings_user_edit',
    'settings_user_view',
    'settings_user_delete',
    'settings_user_roleadmin'
  ];
  
  export const settingsAccess = [
    { label: 'User Maintenance', access: 'settings_user_tab' },
    { label: 'Role Maintenance', access: 'settings_role_tab' }
  ];
  
  export const settingsTabs = [
    { label: 'Users', access: ['settings_user_tab'] },
    { label: 'Roles', access: ['settings_role_tab'] },
  ]
  
  export const adminSettingsTabHashes = [
    {value: 0, hash: '#users'},
    {value: 1, hash: '#roles'},
  ];
  
  export const adminSettingsAccessActions = [
    'settings_user_tab',
    'settings_role_tab',
    'settings_user_add',
    'settings_user_edit',
    'settings_user_view',
    'settings_user_delete',
    'settings_user_roleadmin'
  ]
  