import toast from '../components/Toast';
export default async function lambdaFetch(
  path,
  method,
  webToken,
  body,
  successMessage
) {
  try {

    const customHeader = {
      'content-type': 'application/json',
      ...(webToken && { Authorization: `bearer ${webToken}` }),
    }
    const res = await fetch(`${process.env.REACT_APP_API}/${path}`, {
      method: method,
      headers: customHeader,
      body: body,
    }).then((resp) => resp.json());
    if (!res.success && res.error) {
      toast.persist(res.error, 'error');
      return false
    } else {
      successMessage && toast.success(successMessage);
      return res;
    }
  } catch (e) {
    toast.persist(e.toString(), 'error');
  }
}
