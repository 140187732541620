import React, { Fragment } from 'react';
import MUIDataTable from 'mui-datatables';
import {
  Button,
  Grid,
  Card,
  CardContent,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  Collapse,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import UploadS3Object from '../components/UploadS3Object';
import LoadingButton from '../components/LoadingButton';
import LambdaFetch from '../functions/FetchFromLambda';
import {
  WriteToActivity,
  FieldChangeActivity,
} from '../functions/FieldChangeActivity';
import { documentActions } from '../utils/constants/documentActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Audit from '../components/Audit';
import ConfirmDialog from '../components/ConfirmDialog';
import Comments from '../components/Comments';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { v1 as uuidv1 } from 'uuid';

const docCols = [
  {
    name: 'title',
    label: 'Title',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'action',
    label: 'View/Download',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      filter: false,
      sort: false,
    },
  },
];

const uploadTypes = [
  {
    value: 'c',
    label: 'Local Computer',
  },
  {
    value: 'l',
    label: 'Link',
  },
];

class Documents extends React.Component {
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            // '&:nth-last-child(-n+2)': {
            //   width: 120
            // }
          },
        },
      },
    });
  constructor(props) {
    super(props);
    this.state = {
      access: {},
      isLoadingPage: true,
      addingNewDocument: false,
      content: null,
      docDoesNotExpire: false,
      newUploadType: 'c',
      inputFile: null,
      parentType: null,
      parentId: null,
      isDownloading: false,
      editOpen: false,
      editDoc: null,
      archived: false,
      editDocDoesNotExpire: false,
      editUploadType: 'c',
      comments: []
    };
  }
  getData = async () => {

    const search = new URL(window.location).search.slice(1)
    const searchParams = atob(search).split('&')
    const parentId = searchParams[0];
    const parentType = searchParams[1];

    if (!parentId || !parentType ){
      this.props.createSnack('Invalid url', 'error', 3500);
      this.setState({ isLoadingPage: false });

      return null;
    }

    const [docData, comments] = [await LambdaFetch(
      'documents-2',
      'post',
      this.props.fetchInitialData.credentials.user.accessToken,
      JSON.stringify({
        action: 'read',
        parent_type: parentType,
        parent_id: parentId,
      })
    ), 
    await LambdaFetch(
      'comments',
      'post',
      this.props.fetchInitialData.credentials.user.accessToken,
      JSON.stringify({
        action: 'read',
        obj_type: parentType,
        obj_id: parentId,
      })
    )]
    if (docData.success) {
      this.setState({
        comments: comments.data.comments,
        parentId,
        parentType,
        content: docData.data,
        isLoadingPage: false,
      });
    } else {
      this.setState({ isLoadingPage: false });
    }
  };
  componentDidMount() {
    // VerifyAccess(
    //   (data) => {
    //     this.setState({ access: data.access });
    //   },
    //   documentActions,
    //   this.props.fetchInitialData.credentials.user.jsonWebTok
    // );
    this.getData();
  }
  makeTableData = (data) => {
    const tableData = data.map((doc) => {
      const row = [
        doc.title,
        doc.description,
        doc.reference_type === 'l' ? (
          <a className="editLink" href={doc.document_reference} target="_blank">
            view
          </a>
        ) : (
          <span
            className="editLink"
            onClick={() => this.getFile(doc.document_reference, doc.title, doc)}
          >
            download
          </span>
        ),
        <span
          className="editLink"
          onClick={() =>
            this.setState({
              editOpen: true,
              editDoc: doc,
              editUploadType: doc.reference_type,
            })
          }
        >
          edit
        </span>,
      ];
      // if (!this.state.access.document_edit) {
        // row.pop();
      // }
      return row;
    });
    return tableData;
  };
  updateFileData = (data) => this.setState({ inputFile: data });
  addNewDocument = async (event) => {
    event.preventDefault();
    if (!this.state.parentId || !this.state.parentType) {
      this.props.createSnack(
        'You must have a vaild id and type before you can submit a document',
        'error',
        4000
      );
      return null;
    }
    event.preventDefault();
    const { docDoesNotExpire } = this.state;
    this.setState({ isSaving: true });

    // if (!docDoesNotExpire) {
    //   if (new Date(event.target.newDocDate.value) > new Date('3000-01-01')) {
    //     this.props.createSnack('Enter a valid end date', 'warning', 3000);
    //     this.setState({ isSaving: false });
    //     return null;
    //   }
    // }

    if (this.state.newUploadType === 'c') {
      if (!this.state.inputFile) {
        this.props.createSnack(
          'You must choose a file to upload',
          'warning',
          3500
        );
        this.setState({ isSaving: false });
        return null;
      }

      if (this.state.inputFile[0].size > 20000000) {
        this.props.createSnack(
          'The file must be smaller than 20MB',
          'warning',
          3500
        );
        this.setState({ isSaving: false });
        return null;
      }
    }

    const newDoc = {
      parent_type: this.state.parentType,
      parent_id: this.state.parentId,
      title: event.target.newDocTitle.value,
      expiration_date: null,
      description: event.target.newDocDescription.value,
      reference_type: this.state.newUploadType,
      status: 1,
      document_reference:
        this.state.newUploadType === 'l' ? event.target.uploadLink.value : null,
      ...(this.state.newUploadType === 'c' && {
        fileData: {
          mime: this.state.inputFile[0].src.split(';')[0].replace('data:', ''),
          data: this.state.inputFile[0].src
            .split(';')[1]
            .replace('base64,', ''),
        },
      }),
    };

    const insertedDoc = await LambdaFetch(
      'documents-2',
      'post',
      this.props.fetchInitialData.credentials.user.accessToken,
      JSON.stringify({ document: newDoc, action: 'create' })
    );
    if (insertedDoc.success) {
      this.props.createSnack('Successfully created document', 'success', 3500);
      this.setState({
        addingNewDocument: false,
        isSaving: false,
        docDoesNotExpire: false,
      });
      this.getData();
    } else {
      this.setState({
        isSaving: false,
      });
    }
  };
  editDoc = async (event) => {
    this.setState({ isSaving: true });
    event.preventDefault();
    let editDoc = this.state.editDoc;
    editDoc.title = event.target.editDocTitle.value;
    editDoc.description = event.target.editDocDescription.value;
    if (editDoc.reference_type === 'l') {
      editDoc.document_reference = event.target.uploadLink.value;
    }
    const updateDoc = await LambdaFetch(
      'documents-2',
      'post',
      this.props.fetchInitialData.credentials.user.accessToken,
      JSON.stringify({ document: editDoc, action: 'update' })
    );

    if (updateDoc.success) {
      const activity = {
        client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
        obj_type: editDoc.parent_type,
        obj_id: editDoc.parent_id,
        activity_type: 'UPDATE',
        activity_string: `${this.props.fetchInitialData.credentials.user.name} updated document information`,
        user_id: this.props.fetchInitialData.credentials.user.uuid,
        field_changes: updateDoc.data.updatedDoc.field_changes,
        type: 'document'
      };

      FieldChangeActivity({ activity }, (status) => {
        this.props.createSnack(
          'Successfully updated document',
          'success',
          3500
        );
        this.setState({
          editOpen: false,
          isSaving: false,
          editDoc: null,
        });
        this.getData();
      });
    } else {
      this.setState({
        isSaving: false,
      });
    }
  };
  uploadFile = (fileData, callback) => {
    if (fileData.src === '') {
      return null;
    }
    if (fileData.size > 5000000) {
      window.alert(fileData.name + ' is too big to upload');
    } else {
      const attachmentId = uuidv1();
      let mime = fileData.src.split(';')[0].replace('data:', '');
      let data = fileData.src.split(';')[1].replace('base64,', '');
      const fetchData = {
        attachment_id: attachmentId,
        key: attachmentId,
        client_id: this.props.fetchInitialData.credentials.user.clientId,
        obj_type: this.props.parentType.toUpperCase(),
        obj_id: this.props.parentId,
        attachment_mine: mime,
        original_name: fileData.name,
        data: data,
        created_by: this.props.fetchInitialData.credentials.user.uuid,
      };
      const stringData = JSON.stringify(fetchData);

      fetch(`${process.env.REACT_APP_API}/upload-s3-object`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
        },
        body: stringData,
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            callback(200, fetchData);
          } else {
            callback(400, fetchData);
          }
        });
    }
  };
  getFile = (key, originalName, docData) => {
    this.setState({ isDownloading: true, fileLoad: key });
    const getObject = {
      key: `${this.props.fetchInitialData.credentials.clientInfo.client_id}/${key}`,
    };
    fetch(`${process.env.REACT_APP_API}/get-s3-object`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.accessToken}`,
      },
      body: JSON.stringify(getObject),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.createSnack(info.error, 'error', 3000);
          return null;
        } else if (info.success) {
          const parsed = info.data.data;
          const read = Buffer.from(parsed.Body.data).toString('base64');
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = `data:${info.data.data.ContentType};base64,${read}`;
          a.download = originalName;
          a.click();
          this.setState({ isDownloading: false });

          const activity = {
            client_id: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            obj_type: docData.parent_type,
            obj_id: docData.parent_id,
            activity_type: 'VIEWED',
            activity_string: `${this.props.fetchInitialData.credentials.user.name} viewed/downloaded ${originalName}`,
            user_id: this.props.fetchInitialData.credentials.user.uuid,
          };

          WriteToActivity({ activity }, () => {
            this.getData();
          });
        }
      })
      .catch((err) => console.log(err));
  };
  updateStatus = async (id, callback) => {
    let newStatus = this.state.editDoc.status === 1 ? 2 : 1;

    const activity = {
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
      obj_type: this.state.editDoc.parent_type,
      obj_id: this.state.editDoc.parent_id,
      activity_type: newStatus === 1 ? 'REACTIVATE' : 'ARCHIVE',
      activity_string: `${this.props.fetchInitialData.credentials.user.name} ${
        newStatus === 1 ? 'reactivated' : 'archived'
      } document: ${this.state.editDoc.title}`,
      user_id: this.props.fetchInitialData.credentials.user.uuid,
    };

    const updateStatus = await LambdaFetch(
      'documents-2',
      'post',
      this.props.fetchInitialData.credentials.user.accessToken,
      JSON.stringify({ status: newStatus, document_id: id, action: 'status' })
    );

    if (updateStatus.success) {
      this.setState({ editOpen: false, editDoc: null }, () => {
        this.props.createSnack(
          `Successfully ${
            newStatus === 1 ? 'reactivated' : 'archived'
          } document`,
          'success',
          3500
        );

        WriteToActivity({ activity }, () => {
          callback(200);
          this.getData();
        });
      });
    } else {
      callback(300);
    }
  };
  viewArchived = () => {
    const statusVal = this.state.archived ? 2 : 1;
    const filteredDocuments = this.state.content.documents.filter(
      (doc) => doc.status === statusVal
    );
    return this.makeTableData(filteredDocuments);
  };
  render() {
    if(!this.props.fetchInitialData.credentials.user)  {
      return null
    }
    if (this.state.isLoadingPage) {
      return (
        <div style={{ height: '100vh' }}>
          <div style={{ position: 'relative', left: '50%', top: '40%' }}>
            <CircularProgress
              style={{ color: 'rgb(232,102,19)' }}
              disableShrink
            />
          </div>
        </div>
      );
    }
    if (!this.state.content) {
      return null;
    } else {
      const tableData = this.viewArchived();
      let viewNum = this.state.content.documents.length - tableData.length;

      const cols = docCols;

      return (
        <div>
          {this.state.editOpen && (
            <Dialog
              fullWidth
              maxWidth="sm"
              open={this.state.editOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Fragment>
                <form onSubmit={this.editDoc}>
                  <DialogTitle id="alert-dialog-title">
                    Edit Document
                  </DialogTitle>
                  <DialogContent style={{ overflowX: 'hidden' }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          id="editDocTitle"
                          required
                          defaultValue={this.state.editDoc.title}
                          label="Title"
                          size="small"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          autoComplete="off"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          id="editDocDescription"
                          multiline
                          fullWidth
                          defaultValue={this.state.editDoc.description}
                          margin="dense"
                          label="Description"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          autoComplete="off"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
    
                        <TextField
                          style={{
                            display:
                              this.state.editUploadType !== 'l' ? 'none' : '',
                          }}
                          id="uploadLink"
                          required={this.state.editUploadType === 'l'}
                          fullWidth
                          defaultValue={this.state.editDoc.document_reference}
                          helperText="Must be in the form of: https://..."
                          margin="dense"
                          label="Document Link"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          autoComplete="off"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="text"
                      onClick={() => this.setState({ editOpen: false })}
                    >
                      Cancel
                    </Button>
                      <ConfirmDialog
                        handleConfirm={this.updateStatus}
                        type="document"
                        title={
                          this.state.editDoc.status === 1
                            ? 'Archive Document'
                            : 'Reactivate Document'
                        }
                        color={
                          this.state.editDoc.status === 1 ? 'red' : 'green'
                        }
                        dialogTitle={
                          this.state.editDoc.status === 1
                            ? 'Archive'
                            : 'Reactivate'
                        }
                        dialogText={`Are you sure you want to ${
                          this.state.editDoc.status === 1
                            ? 'archive'
                            : 'reactivate'
                        } this document?`}
                        id={this.state.editDoc.document_id}
                        createSnack={this.props.createSnack}
                      />
                    <LoadingButton
                      label="Save"
                      isLoading={this.state.isSaving}
                      color="primaryVLButton"
                      buttonType="submit"
                    />
                  </DialogActions>
                </form>
              </Fragment>
            </Dialog>
          )}

          {this.state.addingNewDocument && (
            <Dialog
              fullWidth
              maxWidth="sm"
              open={this.state.addingNewDocument}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Fragment>
                <form onSubmit={this.addNewDocument}>
                  <DialogTitle id="alert-dialog-title">
                    Add Document
                  </DialogTitle>
                  <DialogContent style={{ overflowX: 'hidden' }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          id="newDocTitle"
                          required
                          label="Title"
                          size="small"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          id="newDocDescription"
                          multiline
                          fullWidth
                          margin="dense"
                          label="Description"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          autoComplete="off"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          id="newUploadType"
                          select
                          fullWidth
                          margin="dense"
                          label="Upload Type"
                          value={this.state.newUploadType}
                          onChange={(event) =>
                            this.setState({
                              newUploadType: event.target.value,
                            })
                          }
                          variant="outlined"
                          autoComplete="off"
                        >
                          {uploadTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        {this.state.newUploadType === 'c' && (
                          <UploadS3Object
                            updateFileData={this.updateFileData}
                            myStyle={JSON.stringify({
                              position: 'relative',
                              top: '15px',
                              left: '5px',
                            })}
                          />
                        )}
                        <TextField
                          style={{
                            display:
                              this.state.newUploadType !== 'l' ? 'none' : '',
                          }}
                          id="uploadLink"
                          required={this.state.newUploadType === 'l'}
                          fullWidth
                          helperText="Must be in the form of: https://..."
                          margin="dense"
                          label="Document Link"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          autoComplete="off"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      className="secondaryVLButton"
                      onClick={() =>
                        this.setState({ addingNewDocument: false })
                      }
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      label="Save"
                      isLoading={this.state.isSaving}
                      color="primaryVLButton"
                      buttonType="submit"
                    />
                  </DialogActions>
                </form>
              </Fragment>
            </Dialog>
          )}
          <div style={{ marginBottom: '2rem' }}>
            {/* {!!this.state.access.document_add && ( */}
              <div
                style={{
                  position: 'relative',
                  height: '36px',
                  marginBottom: '1rem',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    float: 'right',
                    height: '30px',
                  }}
                >
                  <Button
                    className="primaryVLButton"
                    onClick={() => this.setState({ addingNewDocument: true })}
                  >
                    <AddIcon /> Add Document
                  </Button>
                </div>
              </div>
  

            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                title={
                  this.state.parentType &&
                  this.state.parentId &&
                  `${this.state.parentType} - ${this.state.parentId} (${
                    this.state.archived ? 'Archived' : 'Active'
                  }) `
                }
                data={tableData}
                columns={cols}
                options={{
                  selectableRows: 'none',
                  filterType: 'dropdown',
                  responsive: 'standard',
                  print: false,
                  rowHover: false,
                  download: false,
                  filter: false,
                }}
              />
            </MuiThemeProvider>
              <div style={{ margin: '1rem 0', position: 'realtive' }}>
                <Button
                  color={this.state.archived ? 'primary' : 'secondary'}
                  variant="outlined"
                  onClick={() =>
                    this.setState({ archived: !this.state.archived })
                  }
                >
                  {this.state.archived
                    ? `View Active (${viewNum}) `
                    : `View Archived (${viewNum})`}
                </Button>
              </div>
          
          </div>
          <Comments comments={this.state.comments} 
          objId= {this.state.parentId} 
          objType={this.state.parentType} 
          getData={this.getData}
          accessToken={this.props.fetchInitialData.credentials.user.accessToken}
          createSnack={this.props.createSnack}/>
          <Audit
            title="Document Audit"
            activity={this.state.content.audit}
            timezone={this.props.fetchInitialData.credentials.user.timezone}
            appWidth={1000}
          />
        </div>
      );
    }
  }
}

export default Documents;
