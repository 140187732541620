import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  withStyles,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import LoadingButton from '../components/LoadingButton';
import {
  userMaintenanceColumns,
  vendorUserMaintenanceColumnsErp,
  vendorUserMaintenanceColumnsVl,
} from '../utils/constants/userCols';
import {
  WriteToActivity,
  FieldChangeActivity,
} from '../functions/FieldChangeActivity';

const styles = () => ({
  generalAdd: {
    backgroundColor: 'rgb(232,102,19)',
    marginTop: '1rem',
    cursor: 'pointer',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, 0.9)',
    },
  },
  editLink: {
    margin: 'auto',
    color: 'rgba(0,0,255, 0.8)',
    cursor: 'pointer',
    '&:hover': {
      color: 'rgba(0,0,255, 1)',
      textDecoration: 'underline',
    },
  },
});

class UserComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      content: null,
      currentEntry: null,
      users: [],
      showArchived: false,
      confirmDelete: false,
      confirmActivate: false,
      vendorSelect: null,
    };
    this.makeTableData = this.makeTableData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const requests = [
      {
        url: `${process.env.REACT_APP_API}/get-or-update-users`,
        body: JSON.stringify({
          client_id: this.props.client_id,
          userType: this.props.userType,
          vendorId: this.props.vendorId ? this.props.vendorId : null,
          roleType: this.props.userType === 'Vendor' ? 'Vendor' : 'Client',
        }),
      },
      {
        url: `${process.env.REACT_APP_API}/roles`,
        body: JSON.stringify({
          action: 'get',
          flag: 'ACTIVE',
          roleType: this.props.userType === 'Vendor' ? 'Vendor' : 'Client',
        }),
      },
    ];

    Promise.all(
      requests.map((request) => {
        return fetch(request.url, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            Authorization: `bearer ${this.props.jsonWebTok}`,
          },
          body: request.body,
        })
          .then((resp) => resp.json())
          .catch((e) => {
            console.log('Error: ', e);
          });
      })
    )
      .then((info) => {
        let data = info[0];

        let usersWithRoles = data.data.userRoles.map((user) => {
          let currUser = Object.assign({}, user);
          currUser.roles = user.roles ? JSON.parse(user.roles.toString()) : [];
          return currUser;
        });

        this.setState({
          users: usersWithRoles,
          allRoles: info[1].data.roles,
          content: usersWithRoles,
        });
      })
      .catch((error) => {
        this.props.props.fetchInitialData.createSnack(
          `Error: ${error}`,
          'error',
          3000
        );
      });
  }

  makeTableData(unfilteredRows, flag, access) {
    const { classes } = this.props;
    let userFilterFlag = unfilteredRows.filter(
      (user) => user.delete_flag === flag
    );

    return userFilterFlag.map((option) => {
      let reducedObj = [];
      if (this.props.userType === 'Vendor') {
        reducedObj.push(option.vendor_name ? option.vendor_name : 'N/A');

        if (
          this.props.props.fetchInitialData.credentials.clientInfo
            .vendor_numbering_scheme === 'ERP'
        ) {
          reducedObj.push(option.erp ? option.erp : 'N/A');
        } else {
          reducedObj.push(option.vl_id ? option.vl_id : 'N/A');
        }
      }
      reducedObj.push(option.name);
      reducedObj.push(option.username);
      reducedObj.push(
        option.roles.reduce((count, role) => {
          return (count += role.selected ? 1 : 0);
        }, 0)
      );
      reducedObj.push(
        new Date(option.last_login).getTime()
          ? moment(new Date(option.last_login)).format('MM/DD/YYYY h:mm:ss A')
          : 'N/A'
      );
      reducedObj.push(
        <p
          onClick={() => {
            this.setState({
              isOpen: true,
              currentEntry: option,
            });
          }}
          className={classes.editLink}
        >
          {access.edit ? 'edit' : 'view'}
        </p>
      );
      return reducedObj;
    });
  }

  handleNew = (event) => {
    event.preventDefault();
    if (
      this.props.userType === 'Client' &&
      event.target.newPassword.value.length < 6
    ) {
      this.props.props.fetchInitialData.createSnack(
        'Password must be at least 6 characters long',
        'error',
        3500
      );
      return null;
    }
    this.setState({ isAddingNew: true });

    const newUser = {
      clientId: this.props.props.fetchInitialData.credentials.user.clientId,
      name: event.target.newName.value,
      username: event.target.newUsername.value,
      vendorId: this.props.loggedAsVendor
        ? localStorage.getItem('vendorId')
        : this.state.vendorSelect
        ? this.state.vendorSelect
        : '',
      role: this.props.userType === 'Client' ? '[9]' : '[2]',
      password:
        this.props.userType === 'Client' ? event.target.newPassword.value : '',
      roles: this.state.currentEntry.roles
        .reduce((str, role) => {
          return (str += role.selected ? `${role.role_id},` : '');
        }, '')
        .slice(0, -1),
      delete_flag: 0,
      verified: 1,
      avatar_content: event.target.newName.value
        .split(' ')
        .reduce((a, name) => (a += name.slice(0, 1).toUpperCase()), ''),
    };

    const fetchData = {
      newUser,
      userType: this.props.userType.toLowerCase(),
      add: true,
    };
    fetch(`${process.env.REACT_APP_API}/get-or-update-users`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.props.fetchInitialData.createSnack(
            info.error,
            'error',
            3000
          );
        } else if (info.success) {
          const activity = {
            CLIENT_ID: this.props.client_id,
            OBJ_TYPE: this.props.userType === 'Vendor' ? 'VENDOR' : 'USER',
            OBJ_ID:
              this.props.userType === 'Vendor' ? this.props.vendorId : 'Client',
            ACTIVITY_TYPE: 'CREATE',
            ACTIVITY_STRING: `${this.props.props.fetchInitialData.credentials.user.name} created user ${newUser.name}`,
            USER_ID: this.props.props.fetchInitialData.credentials.user.uuid,
          };
          WriteToActivity({ activity }, () => {
            this.setState({
              isAddingNew: false,
              currentEntry: null,
              isNewEntry: false,
            });
            this.getData();
          });

          this.props.props.fetchInitialData.createSnack(
            'Successfully created user',
            'success',
            3000
          );
          this.setState({
            isAddingNew: false,
            currentEntry: null,
            isNewEntry: false,
          });
          this.getData();
        } else {
          this.props.props.fetchInitialData.createSnack(
            'There was an error',
            'success',
            3000
          );
        }
        this.setState({ isAddingNew: false });
      });
  };

  handleEdit = (event) => {
    event.preventDefault();
    this.setState({ isEditing: true });

    const updatedUser = {
      uuid: this.state.currentEntry.uuid,
      clientId: this.props.props.fetchInitialData.credentials.user.clientId,
      name: event.target.editName.value,
      username: event.target.editUsername.value,
      roles: this.state.currentEntry.roles
        .reduce((str, role) => {
          return (str += role.selected ? `${role.role_id},` : '');
        }, '')
        .slice(0, -1),
    };
    const fetchData = {
      updatedUser,
      userType: this.props.userType.toLowerCase(),
      update: true,
    };
    fetch(`${process.env.REACT_APP_API}/get-or-update-users`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.props.fetchInitialData.createSnack(
            info.error,
            'error',
            3000
          );
        } else if (info.success) {
          this.props.props.fetchInitialData.createSnack(
            'Successfully updated user',
            'success',
            3000
          );

          const activity = {
            CLIENT_ID: this.props.client_id,
            OBJ_TYPE: this.props.userType === 'Vendor' ? 'VENDOR' : 'USER',
            OBJ_ID:
              this.props.userType === 'Vendor' ? this.props.vendorId : 'Client',
            ACTIVITY_TYPE: 'UPDATE',
            ACTIVITY_STRING: `Updated user ${updatedUser.name}`,
            USER_ID: this.props.props.fetchInitialData.credentials.user.uuid,
            field_changes: info.data.updateUser.field_changes,
            type: 'user',
          };

          FieldChangeActivity({ activity }, () => {
            this.setState({ isOpen: false, currentEntry: null });
            this.getData();
          });
        } else {
          this.props.props.fetchInitialData.createSnack(
            'There was an error',
            'success',
            3000
          );
        }
        this.setState({ isEditing: false });
      });
  };
  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ isDeleting: true, isActivating: true });
    const fetchData = {
      uuid: this.state.currentEntry.uuid,
      delete: this.state.currentEntry.delete_flag === 0,
      activate: this.state.currentEntry.delete_flag !== 0,
      userType: this.props.userType.toLowerCase(),
    };
    fetch(`${process.env.REACT_APP_API}/get-or-update-users`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.props.fetchInitialData.createSnack(
            info.error,
            'error',
            3000
          );
        } else if (info.success) {
          this.props.props.fetchInitialData.createSnack(
            `Successfully ${fetchData.delete ? 'deleted' : 'activated'} user`,
            'success',
            3000
          );
          this.setState({
            confirmDelete: false,
            confirmActivate: false,
            currentEntry: null,
            isOpen: false,
          });
          this.getData();
        } else {
          this.props.props.fetchInitialData.createSnack(
            'There was an error',
            'error',
            3000
          );
        }
        this.setState({ isDeleting: false, isActivating: false });
      });
  };

  makeCheckBox(roleValue, label, isChecked, readOnly) {
    return (
      <FormControlLabel
        key={roleValue}
        control={
          <Checkbox
            style={{ color: readOnly ? 'grey' : '#E86613' }}
            checked={isChecked}
            disabled={readOnly}
            onChange={() => this.handleCheckChange(roleValue)}
            value={roleValue}
          />
        }
        label={label}
      />
    );
  }

  handleCheckChange = (roleId) => {
    let updatedCurrentEntry = Object.assign({}, this.state.currentEntry);
    updatedCurrentEntry.roles = this.state.currentEntry.roles.map((role) => {
      if (role.role_id === roleId) {
        return Object.assign({}, role, { selected: role.selected ? 0 : true });
      } else {
        return role;
      }
    });
    this.setState({ currentEntry: updatedCurrentEntry });
  };
  handleClose = () => {
    this.setState({
      isOpen: false,
      currentEntry: null,
      isNewEntry: false,
      confirmActivate: false,
    });
  };
  checkAccess = () => {
    const { access } = this.props;
    let renderAccess;
    if (this.props.userType === 'Client') {
      renderAccess = {
        view: access.settings_user_view,
        edit: access.settings_user_edit,
        add: access.settings_user_add,
        checkbox: access.settings_user_roleadmin,
        delete: access.settings_user_delete,
      };
    } else {
      renderAccess = {
        view: access.settings_vendoruser_view,
        edit: access.settings_vendoruser_edit,
        add: access.settings_vendoruser_add,
        checkbox: access.settings_vendoruser_roleadmin,
        delete: access.settings_vendoruser_delete,
      };
    }
    return renderAccess;
  };

  render() {
    const { classes } = this.props;
    if (!this.state.content) {
      return (
        <div style={{ height: '100vh' }}>
          <div style={{ position: 'relative', left: '50%', top: '40%' }}>
            <CircularProgress
              style={{ color: 'rgb(232,102,19)' }}
              disableShrink
            />
          </div>
        </div>
      );
    }
    let access = this.checkAccess();
    let tableUsers = this.makeTableData(
      this.state.users,
      this.state.showArchived ? 1 : 0,
      access
    );
    const vendorNumbering = this.props.props.fetchInitialData.credentials
      .clientInfo.vendor_numbering_scheme;

    const tableCols =
      this.props.userType === 'Client'
        ? userMaintenanceColumns
        : vendorNumbering === 'ERP'
        ? vendorUserMaintenanceColumnsErp
        : vendorUserMaintenanceColumnsVl;
    return (
      <div>
        <Dialog
          open={this.state.confirmDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={() => this.handleClose()}
        >
          <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this user?
          </DialogContent>
          <DialogActions>
            <form onSubmit={this.handleDelete}>
              <Button
                style={{ marginRight: '0.5rem' }}
                onClick={() => this.setState({ confirmDelete: false })}
              >
                Cancel
              </Button>
              <LoadingButton
                label="Confirm"
                isLoading={this.state.isDeleting}
                color="primaryVLButton"
                buttonType="submit"
              />
            </form>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.confirmActivate}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={() => this.handleClose()}
        >
          <DialogTitle id="alert-dialog-title">Confirm Activation</DialogTitle>
          <DialogContent>
            Are you sure you want to activate this user?
          </DialogContent>
          <DialogActions>
            <form onSubmit={this.handleDelete}>
              <Button
                style={{ marginRight: '0.5rem' }}
                onClick={() => this.setState({ confirmActivate: false })}
              >
                Cancel
              </Button>

              <LoadingButton
                label="Confirm"
                isLoading={this.state.isActivating}
                color="primaryVLButton"
                buttonType="submit"
              />
            </form>
          </DialogActions>
        </Dialog>

        {this.state.isNewEntry && (
          <Dialog
            open={this.state.isNewEntry}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => this.handleClose()}
          >
            <form onSubmit={this.handleNew}>
              <DialogTitle id="alert-dialog-title">
                {`${
                  this.props.userType === 'Client'
                    ? 'Add User'
                    : 'Add Vendor User'
                }`}
              </DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={2}>
                  {/* {this.props.userType === 'Vendor' && (
                          <Grid item xs={12} sm={12} md={12}>
                            {!this.props.loggedAsVendor && (
                                <SuggestionSql
                                    id="newVendor"
                                    jsonWebTok={this.props.jsonWebTok}
                                    handleChange={(id, newVendorName, isSelected) => {
                                      if (isSelected) {
                                        this.setState({ vendorSelect: id });
                                      } else {
                                        this.setState({ vendorSelect: null });
                                      }
                                    }}
                                    placeholder=""
                                    label="Vendor"
                                    required
                                    fullWidth
                                    clientId={this.props.props.fetchInitialData.credentials.user.clientId}
                                />
                            )}

                          </Grid>
                      )} */}
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="newName"
                      label="Name"
                      fullWidth
                      required
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="newUsername"
                      label="Email"
                      fullWidth
                      required
                      type="email"
                      autoComplete="off"
                    />
                  </Grid>
                  {this.props.userType === 'Client' && (
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="newPassword"
                        label="Password"
                        fullWidth
                        required
                        type="password"
                        autoComplete="off"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    {this.state.currentEntry.roles.map((role) => {
                      return this.makeCheckBox(
                        role.role_id,
                        role.description,
                        !!role.selected
                      );
                    })}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.handleClose();
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  label="Confirm"
                  isLoading={this.state.isAddingNew}
                  color="primaryVLButton"
                  buttonType="submit"
                />
              </DialogActions>
            </form>
          </Dialog>
        )}

        {this.state.currentEntry && (
          <Dialog
            open={this.state.isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => this.handleClose()}
          >
            <form onSubmit={this.handleEdit}>
              <DialogTitle id="alert-dialog-title">Edit User</DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="editName"
                      label="Name"
                      fullWidth
                      defaultValue={this.state.currentEntry.name}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="editUsername"
                      label="Email"
                      fullWidth
                      type="email"
                      defaultValue={this.state.currentEntry.username}
                      autoComplete="off"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    {this.state.currentEntry.roles.map((role) => {
                      return this.makeCheckBox(
                        role.role_id,
                        role.role_description,
                        !!role.selected,
                        !access.checkbox
                      );
                    })}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.handleClose();
                  }}
                >
                  Cancel
                </Button>
                {!!access.delete && (
                  <Fragment>
                    {this.state.currentEntry.delete_flag === 0 ? (
                      <Button
                        style={{ color: 'red' }}
                        onClick={() => {
                          this.setState({ confirmDelete: true, isOpen: false });
                        }}
                      >
                        Delete
                      </Button>
                    ) : (
                      <Button
                        style={{ color: 'green' }}
                        onClick={() => {
                          this.setState({ confirmActivate: true });
                        }}
                      >
                        Activate
                      </Button>
                    )}
                  </Fragment>
                )}
                {!!access.edit && (
                  <LoadingButton
                    label="Confirm"
                    isLoading={this.state.isEditing}
                    color="primaryVLButton"
                    buttonType="submit"
                  />
                )}
              </DialogActions>
            </form>
          </Dialog>
        )}

        <MUIDataTable
          title={`${this.state.showArchived ? 'Deleted' : ''} ${
            this.props.userType
          } Users`}
          data={tableUsers}
          columns={tableCols}
          options={{
            customSort: (data, colIndex, order) => {
              return data.sort((a, b) => {
                if (colIndex === 6) {
                  return a.data[colIndex] === 'N/A'
                    ? -1 * (order === 'desc' ? 1 : -1)
                    : (new Date(a.data[colIndex]) < new Date(b.data[colIndex])
                        ? -1
                        : 1) * (order === 'desc' ? 1 : -1);
                } else {
                  return (
                    (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
                    (order === 'desc' ? 1 : -1)
                  );
                }
              });
            },
            filter: false,
            selectableRows: 'none',
            filterType: 'dropdown',
            responsive: 'standard',
            print: false,
          }}
        />
        <div>
          {!!access.add && (
            <Button
              className={classes.generalAdd}
              onClick={() =>
                this.setState({
                  isNewEntry: true,
                  currentEntry: {
                    roles: this.state.allRoles,
                  },
                })
              }
            >
              + Add a User
            </Button>
          )}
          <div style={{ float: 'right', margin: '1rem 0' }}>
            <Button
              onClick={() => {
                this.setState({ showArchived: !this.state.showArchived });
              }}
              variant="outlined"
              style={{ color: '#E86613', borderColor: '#E86613' }}
            >
              {this.state.showArchived
                ? `View Active Users (${
                    this.state.users.length - tableUsers.length
                  })`
                : `View Deleted Users (${
                    this.state.users.length - tableUsers.length
                  })`}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

UserComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(UserComponent));
