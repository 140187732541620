import React from 'react';
import {
  Typography,
  Button,
  Fade,
  CircularProgress,
  TextField,
  InputAdornment,
  withStyles,
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import LambdaFetch from '../functions/FetchFromLambda';
import './Login.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      failedLogin: false,
    };
  }

  componentDidMount() {
    if (this.props.fetchInitialData.credentials.user.uuid) {
      this.props.history.push('/documents');
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      failedLogin: false,
    });
    const loginInfo = {
      username: event.target.loginemail.value,
      password: event.target.loginpassword.value,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };
    const userInfo = await LambdaFetch(
      'login',
      'post',
      null,
      JSON.stringify(loginInfo)
    );

    if (userInfo.success) {
      this.props.fetchInitialData.auth(userInfo.data);
      this.props.history.push(
        window.location.search
          ? `/documents${window.location.search}`
          : '/documents'
      );
    }
    this.setState({
      isLoading: false,
      failedLogin: true,
    });
  };

  render() {
    if (!this.props.fetchInitialData.credentials) {
      return null;
    } else if (!this.props.fetchInitialData.credentials.user.uuid) {
      const { classes } = this.props;
      const failedLogin = this.state.failedLogin
        ? {
            fontSize: '0.7rem',
            marginTop: '10px',
            lineHeight: '15px',
            color: 'red',
            textAlign: 'center',
          }
        : { display: 'none' };
      return (
        <>
          <div
            className="login-container"
            style={{ maxWidth: '1000px', margin: 'auto' }}
          >
            <div className="vertical-center">
              <div style={{ textAlign: 'center' }}>
                <div style={{ width: 'auto', disply: 'inline-block' }}>
                  <img
                    alt={'client logo'}
                    src={this.props.fetchInitialData.credentials.clientInfo.logo_img}
                    style={{
                      height: 'auto',
                      width: '25%',
                      margin: '1rem auto',
                    }}
                  />
                </div>
              </div>
              <div style={{ maxWidth: '500px', margin: 'auto' }}>
                <Grid container style={{ marginTop: '1rem' }} spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ textAlign: 'center' }} variant="h5">
                      Sign In
                    </Typography>

                    <hr />
                    <form onSubmit={this.handleSubmit}>
                      <div>
                        <TextField
                          className="loginInput"
                          id="loginemail"
                          label="Email"
                          required
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                            classes: { notchedOutline: classes.outline },
                          }}
                          autoComplete="off"
                        />
                      </div>
                      <div>
                        <TextField
                          className="loginInput"
                          id="loginpassword"
                          required
                          fullWidth
                          type="password"
                          label="Password"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                            classes: { notchedOutline: classes.outline },
                          }}
                          autoComplete="off"
                        />
                      </div>
                      <div className={classes.noOutline}>
                        <Button
                          type="submit"
                          className={classes.loginButton}
                          variant="contained"
                        >
                          {!this.state.isLoading && 'Log In'}
                          <Fade
                            style={{ position: 'relative', left: '10px' }}
                            in={this.state.isLoading}
                            unmountOnExit
                          >
                            <CircularProgress
                              style={{ color: 'white' }}
                              variant="indeterminate"
                              disableShrink
                              size={30}
                              thickness={4}
                            />
                          </Fade>
                        </Button>
                        <Typography style={failedLogin} variant="body2">
                          Failed to login, Username or password is incorrect
                        </Typography>
                      </div>
                      {/* <div style={{ textAlign: 'center' }}>
                        <Link
                          style={{ fontSize: '0.75rem', color: 'black' }}
                          to="/forgotpassword"
                        >
                          Forgot your password?
                        </Link>
                      </div> */}
                    </form>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="homeCard">
          <Grid container spacing={2}>
            <h3>Welcome Back!</h3>
          </Grid>
        </div>
      );
    }
  }
}

const styles = {
  outline: {
    borderRadius: '100px',
  },
  label: {
    textTransform: 'capitalize',
  },
  loginButton: {
    height: 50,
    width: '100%',
    borderRadius: '100px !important',
    marginTop: 8,
    backgroundColor: '#656565 !important',
    color: 'white !important',
    '&:focus': {
      outline: 'none',
    },
  },
  buttonLabel: {
    '& > a': {
      '&:hover': {
        textDecoration: 'none !important',
      },
    },
  },

  noOutline: {
    '&:focus': {
      outline: 'none',
    },
  },
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withSnackbar(withRouter(withStyles(styles)(Login)));
