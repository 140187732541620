import React from 'react';
import MUIDataTable from 'mui-datatables';
import { TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LoadingButton from '../components/LoadingButton';
import { withSnackbar } from 'notistack';
import FormGroup from '@material-ui/core/FormGroup';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';

class RoleMaintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      rolesTableData: [],
      allActions: [],
      isEdit: false,
      categories: [],
      showArchivedRoles: false,
      isInactivating: false,
      isActivating: false,
      roleType: 'client',
      confirmDelete: false,
      confirmActivate: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    fetch(`${process.env.REACT_APP_API}/roles`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ action: 'get', flag: -1, roleType: -1 }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success) {
          this.setState({
            content: data.data.roles,
            rolesTableData: this.makeTableData(data.data.roles),
          });
        }
      })
      .catch((error) => {
        this.props.fetchInitialData.createSnack(
          `Error: ${error}`,
          'error',
          3000
        );
      });
  }

  getRoleAccess = (option) => {
    fetch(`${process.env.REACT_APP_API}/roles`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ action: 'roles', roleId: option.role_id }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        let categories = [];
        data.data.roleAccess.forEach((role) => {
          if (categories.indexOf(role.action_category) === -1) {
            categories.push(role.action_category);
          }
        });
        let allActions = data.data.roleAccess.map((role) => {
          return { id: role.action_id, name: role.action_description };
        });
        this.setState({
          currentRoleAccess: data.data.roleAccess,
          allActions,
          currentRole: option,
          categories: categories.sort(),
          isEdit: true,
        });
      })
      .catch((error) => {
        this.props.fetchInitialData.createSnack(
          `Error: ${error}`,
          'error',
          3000
        );
      });
  };

  makeTableData(unfilteredRows, flag) {
    let roleFilterFlag = unfilteredRows.filter(
      (role) => role.role_status === flag
    );
    return roleFilterFlag.map((option) => {
      let reducedObj = [
        option.role_type
          ? `${option.role_type.toUpperCase()} ${
              option.protected === 1 ? '(protected)' : ''
            }`
          : '',
        option.role_name,
        option.description,
      ];
      reducedObj.push(
        <p
          onClick={() => {
            this.getRoleAccess(option);
          }}
          className="editLink"
          style={{margin: 'auto'}}
        >
          {option.protected === 1 ? 'view' : 'edit'}
        </p>
      );
      return reducedObj;
    });
  }

  updateRoleAccess = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    let roleUpdateString = this.state.allActions.reduce((str, action) => {
      let addTo = '';
      if (event.target[`role_${action.id}`]) {
        if (event.target[`role_${action.id}`].checked) {
          addTo = `${action.id.toString()},`;
        }
      }

      return str + addTo;
    }, '');

    let selectedActions = roleUpdateString.slice(0, -1);
  
    fetch(`${process.env.REACT_APP_API}/roles`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        action: 'update',
        roleId: this.state.currentRole.role_id,
        name: event.target.roleName.value,
        description: event.target.roleDescription.value,
        selectedActions,
        flag: 'ACTIVE',
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.error) {
          this.props.fetchInitialData.createSnack(data.error, 'error', 3000);
          this.setState({ isLoading: false });
        } else if (data.success) {
          this.props.fetchInitialData.createSnack(
            'Successfully updated role',
            'success',
            3000
          );
          this.setState({ isLoading: false, isEdit: false });
          this.getData()
        } else {
          this.props.fetchInitialData.createSnack(
            'There was an error',
            'error',
            3000
          );
          this.setState({ isLoading: false });
        }
      });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });
    fetch(`${process.env.REACT_APP_API}/roles`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        action: 'new',
        name: event.target.name.value,
        description: event.target.description.value,
        roleType: 'client',
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success) {
          this.props.fetchInitialData.createSnack(
            'Successfully created new role',
            'success',
            3000
          );
          this.setState({
            isLoading: false,
            isNewEntry: false,
            roleType: 'client',
          });
          this.getData();
        } else {
          this.props.fetchInitialData.createSnack(
            'There was an error creating new role',
            'error',
            3000
          );
          this.setState({ isLoading: false });
        }
      });
  };
  handleCheck = (id) => {
    const updatedRoleAccess = this.state.currentRoleAccess.map((action) => {
      if (action.action_id === id) {
        action.selected = !action.selected;
      }
      return action;
    });
    this.setState({ currentRoleAccess: updatedRoleAccess });
  };
  toggleAll = (cat) => {
    const filtered = this.state.currentRoleAccess.filter(
      (role) => role.action_category === cat
    );
    const hasUnselected = filtered.some((action) => action.selected !== 1);

    const updatedRoleAccess = this.state.currentRoleAccess.map((role) => {
      if (role.action_category === cat) {
        return Object.assign({}, role, { selected: hasUnselected ? 1 : 0 });
      } else return role;
    });
    this.setState({ currentRoleAccess: updatedRoleAccess });
  };
  handleClose = () => {
    this.setState({ isNewEntry: false, confirmDelete: false, isEdit: false });
  };
  handleActivation = () => {
    this.setState({ isInactivating: true, isActivating: true });
    fetch(`${process.env.REACT_APP_API}/roles`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        action: 'status',
        role_id: this.state.currentRole.role_id,
        role_status:
          this.state.currentRole.role_status === 'ACTIVE'
            ? 'INACTIVE'
            : 'ACTIVE',
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success) {
          this.props.fetchInitialData.createSnack(
            'Successfully updated role',
            'success',
            3000
          );
          this.setState({
            isInactivating: false,
            isActivating: false,
            isEdit: false,
            confirmDelete: false,
            confirmActivate: false,
          });
          this.getData();
        } else {
          this.props.fetchInitialData.createSnack(
            'There was an error updating role',
            'error',
            3000
          );
          this.setState({ isInactivating: false, isActivating: false });
        }
      });
  };

  render() {
    if (!this.state.content) {
      return (
        <div style={{ height: '100vh', width: '100%' }}>
          <div style={{ position: 'relative', left: '50%', top: '40%' }}>
            <CircularProgress
              style={{ color: 'rgb(232,102,19)' }}
              disableShrink
            />
          </div>
        </div>
      );
    }

    const currentRoles = this.makeTableData(
      this.state.content,
      this.state.showArchivedRoles ? 'INACTIVE' : 'ACTIVE'
    );

    return (
      <div style={{ width: '100%' }}>
        <Dialog
          open={this.state.confirmDelete}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Inactivation
          </DialogTitle>
          <DialogContent>
            Are you sure you want to inactivate this role?
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  confirmDelete: false,
                });
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              label="Confirm"
              isLoading={this.state.isInactivating}
              color="primaryVLButton"
              fn={this.handleActivation}
            />
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.confirmActivate}
          onClose={() => this.setState({ confirmActivate: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Activation</DialogTitle>
          <DialogContent>
            Are you sure you want to activate this role?
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: '0.5rem' }}
              onClick={() => this.setState({ confirmActivate: false })}
            >
              Cancel
            </Button>
            <LoadingButton
              label="Confirm"
              isLoading={this.state.isActivating}
              color="primaryVLButton"
              fn={this.handleActivation}
            />
          </DialogActions>
        </Dialog>

        <div className="approvalSettings">
          {this.state.isNewEntry && (
            <Dialog
              open={this.state.isNewEntry}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <form onSubmit={this.handleSubmit}>
                <DialogTitle id="alert-dialog-title">New Role</DialogTitle>
                <DialogContent>
                  <Grid container justify="flex-start" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField id="name" label="Name" fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="description"
                        label="Description"
                        fullWidth
                        required
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        className="textfield"
                        select
                        required={true}
                        id="roleType"
                        label="Role Type"
                        value={this.state.roleType}
                        onChange={(e) =>
                          this.setState({ roleType: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <span />
                            </InputAdornment>
                          ),
                        }}
                      >
                        {[
                          { value: 'client', label: 'Client' },
                          { value: 'vendor', label: 'Vendor' },
                        ].map((option) => {
                          return (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid> */}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      this.setState({
                        isNewEntry: false,
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    label="Confirm"
                    isLoading={this.state.isLoading}
                    color="primaryVLButton"
                    buttonType="submit"
                  />
                </DialogActions>
              </form>
            </Dialog>
          )}

          {this.state.isEdit && (
            <Dialog
              open={this.state.isEdit}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="lg"
            >
              <form onSubmit={this.updateRoleAccess}>
                <DialogTitle id="alert-dialog-title">
                  {`Role: ${this.state.currentRole.role_name}`}
                </DialogTitle>
                <DialogContent>

                  <FormGroup>
                    <Grid container justify="flex-start" spacing={0}>
                    <Grid style={{marginBottom: '1rem'}} item xs={12} md={12} lg={12}>
                    <TextField margin="dense" id="roleName" label="Name" defaultValue={this.state.currentRole.role_name}/>
                     
                      <TextField style={{marginLeft: '0.5rem'}} margin="dense" id="roleDescription" 
                      label="Description" defaultValue={this.state.currentRole.description}/>
                      </Grid>
               
                      {this.state.categories
                        .filter((cat) => cat !== 'App')
                        .map((cat) => {
                          let filtered = this.state.currentRoleAccess.filter(
                            (role) => role.action_category === cat
                          );
                          let selectedCount = filtered.reduce(
                            (count, cur) => (count += cur.selected ? 1 : 0),
                            0
                          );

                          return (
                            <Grid item xs={12} md={6} lg={6}>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>{cat}</Typography>
                                  <Typography
                                    style={{
                                      fontSize: '0.75rem',
                                      color: '#545454',
                                      marginLeft: '0.5rem',
                                      marginTop: '0.25rem',
                                      textAlign: 'right',
                                    }}
                                  >
                                    <i
                                      style={{
                                        color:
                                          selectedCount === filtered.length
                                            ? 'green'
                                            : '',
                                      }}
                                    >{`(${selectedCount} of ${filtered.length} selected)`}</i>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ display: 'block' }}>
                                {this.state.currentRole.protected !== 1 && (
                                <Button
                                    size="small"
                                    style={{ color: '#545454' }}
                                    variant="outlined"
                                    onClick={() => this.toggleAll(cat)}
                                  >
                                    Toggle All
                                  </Button>
                                )}
                                  {this.state.currentRoleAccess
                                    .filter(
                                      (role) => role.action_category === cat
                                    )
                                    .map((action) => {
                                      return (
                                        <div
                                          style={{ display: 'block' }}
                                          key={action.action_id.toString()}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                disabled={this.state.currentRole.protected === 1}
                                                id={`role_${action.action_id}`}
                                                style={{ color: this.state.currentRole.protected === 1 ? '' : '#E86613' }}
                                                onChange={() =>
                                                  this.handleCheck(
                                                    action.action_id
                                                  )
                                                }
                                                checked={!!action.selected}
                                                name={action.action}
                                              />
                                            }
                                            label={action.action_description}
                                          />
                                        </div>
                                      );
                                    })}
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </FormGroup>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      this.setState({
                        isEdit: false,
                      });
                    }}
                  >
                    {this.state.currentRole.protected !== 1 ? 'Cancel' : 'Back'}
                  </Button>
                  {this.state.currentRole.protected !== 1 && (
                    <>
                      {this.state.currentRole.role_status === 'ACTIVE' ? (
                        <Button
                          disabled={
                            this.state.currentRole.protected === 1
                              ? true
                              : false
                          }
                          style={{ color: 'red' }}
                          onClick={() => {
                            this.setState({ confirmDelete: true });
                          }}
                        >
                          Inactivate
                        </Button>
                      ) : (
                        <Button
                          disabled={
                            this.state.currentRole.protected === 1
                              ? true
                              : false
                          }
                          style={{ color: 'green' }}
                          onClick={() => {
                            this.setState({ confirmActivate: true });
                          }}
                        >
                          Activate
                        </Button>
                      )}
                    </>
                  )}
                  {this.state.currentRole.protected !== 1 && (
                    <LoadingButton
                      label="Save"
                      isLoading={this.state.isLoading}
                      color="primaryVLButton"
                      buttonType="submit"
                    />
                  )}
                </DialogActions>
              </form>
            </Dialog>
          )}

          <div className="rejectContainer">
            <MUIDataTable
              title={this.state.showArchivedRoles ? 'Archived Roles' : 'Roles'}
              data={currentRoles}
              columns={[
                {
                  name: 'roleType',
                  label: 'Role Type',
                  options: {
                    filter: true,
                    sort: false,
                  },
                },
                {
                  name: 'name',
                  label: 'Name',
                  options: {
                    filter: false,
                    sort: true,
                  },
                },
                {
                  name: 'description',
                  label: 'Description',
                  options: {
                    filter: false,
                    sort: true,
                  },
                },
                {
                  name: 'edit',
                  label: 'view/edit',
                  options: {
                    filter: false,
                    sort: false,
                  },
                },
              ]}
              options={{
                selectableRows: false,
                filterType: 'dropdown',
                responsive: 'standard',
                print: false,
              }}
            />

            <div style={{ marginBottom: 30 }}>
              <Button
                style={{
                  backgroundColor: 'rgb(232,84,10)',
                  color: 'white',
                  marginTop: '1rem',
                }}
                onClick={() =>
                  this.setState({
                    isNewEntry: true,
                  })
                }
                className="generalAdd"
              >
                + Add a Role
              </Button>
              <div style={{ float: 'right', margin: '1rem 0' }}>
                <Button
                  onClick={() => {
                    this.setState({
                      showArchivedRoles: !this.state.showArchivedRoles,
                    });
                  }}
                  variant="outlined"
                  style={{ color: '#E86613', borderColor: '#E86613' }}
                >
                  {this.state.showArchivedRoles
                    ? `View Active Roles (${this.state.content.length -
                        currentRoles.length})`
                    : `View Archived Role (${this.state.content.length -
                        currentRoles.length})`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  tabsRoot: {
    borderBottom: '0 solid #e8e8e8',
  },
  tabsIndicator: {
    color: 'rgb(232,102,19)',
    backgroundColor: 'rgb(232,102,19)',
  },
  tabRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: 'rgb(232,102,19)',
      opacity: 1,
    },
  },
});

RoleMaintenance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(RoleMaintenance));
