import React from "react";

class UploadS3Object extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        uploadFiles: []
    };
  }
  inputFileChange = (e) => {
    if (e.target.files[0] === undefined) {
      this.setState({
        uploadFiles: []
      }, () => this.props.updateFileData(null));
    } else {
      this.setState({ uploadFiles: [] });
      for (var i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i],
          reader = new FileReader(),
          self = this;
        reader.onload = function(r) {
          var updatedUpload = self.state.uploadFiles.map(file =>
            Object.assign({}, file)
          );
          updatedUpload.push({
            name: file.name,
            src: r.target.result,
            size: file.size
          });
          self.setState({
            uploadFiles: updatedUpload
          }, () => self.props.updateFileData(updatedUpload));
        };
        
        reader.readAsDataURL(file);
        this.setState({ value: reader });
      }
    }
  }
  render() {
    return (
        <input
        type="file"
        accept=".pdf, .png, .gif, .jpeg, .tiff, .docx"
        style={JSON.parse(this.props.myStyle)}
        onChange={this.inputFileChange}
      />      
    );
  }
}


export default UploadS3Object;
