import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Tabs, Tab } from '@material-ui/core';
import UserMaintenance from '../components/UserMaintenance';
import RoleMaintenance from '../components/RoleMaintenance';
import VerifyAccess from '../functions/VerifyAccess';
import {
  adminSettingsAccessActions,
  adminSettingsTabHashes,
  settingsTabs,
} from '../utils/constants/adminSettings';

class AdminSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appBarWidth: 1000,
      tabValue: 1,
      access: {},
    };
  }
  componentDidMount() {
    VerifyAccess(
      (data) => {
        this.setState({ access: data.access });
      },
      adminSettingsAccessActions,
      this.props.fetchInitialData.credentials.user.jsonWebTok
    );
    this.viewHash();
    this.calcSize();
    window.addEventListener('resize', this.calcSize.bind(this));
  }

  viewHash = () => {
    const hash = window.location.hash;
    const currentHash = adminSettingsTabHashes.find((el) => el.hash === hash);
    const tabVal = currentHash ? currentHash.value : -1;
    this.setState({ tabValue: tabVal === -1 ? 1 : tabVal + 1 });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => {
      window.location.hash = adminSettingsTabHashes
        .find((el) => el.value === newValue - 1)
        .hash.slice(1);
    });
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.calcSize.bind(this));
  }

  calcSize() {
    this.setState({ appBarWidth: window.innerWidth });
  }

  renderSwitch = (param) => {
    switch (param) {
      case 1:
        return (
          <UserMaintenance
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
      case 2:
        return (
          <RoleMaintenance
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div style={{ height: '55px' }}>
        <div
          style={{
            borderBottom: '0px solid rgba(0,0,0,.3)',
            backgroundColor: 'white',
            width: 'auto',
            zIndex: 200,
          }}
        >
          <Tabs
            style={{ padding: 0 }}
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            variant="scrollable"
          >
            {settingsTabs.map((cur, index) => {
              if (
                cur.access.map((currAccess) => this.state.access[currAccess])
              ) {
                return (
                  <Tab
                    key={index}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selected,
                    }}
                    label={cur.label}
                    value={index + 1}
                  />
                );
              }
              return null;
            })}
          </Tabs>
        </div>
        <div style={{ marginTop: '2rem', paddingBottom: '2rem' }}>
          {this.state.tabValue && this.renderSwitch(this.state.tabValue)}
        </div>
      </div>
    );
  }
}

const styles = () => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#E86613',
  },
  selected: {},
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '&:hover': {
      color: '#E86613',
      opacity: 1,
    },
    '&$selected': {
      color: '#E86613',
    },
    '&:focus': {
      color: '#E86613',
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
});

AdminSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminSettings);
