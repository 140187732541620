export default function WriteToActivity(activityObj, callback) {
  fetch(`${process.env.REACT_APP_API}/write-to-apc-activity`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(activityObj),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.success) {
        callback(200);
      } else {
        callback(400);
      }
    });
}
