import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import '../App.css';

const styles = {
  nav: {
    fontSize: '1.3rem',
    backgroundColor: '#E86613',
    color: 'white',
    borderRadius: '5px',
    height: '40px',
    margin: 'auto',
  },
  active: {
    marginTop: '-5px',
    color: '#E86613',
    height: '45px',
    backgroundColor: 'white',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    border: 'solid 1px grey',
    borderBottomColor: 'white',
  },
  topNavActive: {
    background: '#CCC',
    color: '#000',
  },
};

class AppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'home',
      access: {},
    };
  }
  render() {
    return (
      <>
        <div style={{ float: 'right' }}>
          <ButtonGroup size="small">
          {/* <Button
              onClick={() => window.location.pathname = '/settings'}
              className="topMenuButton"
              variant="text"
              style={{ borderTopLeftRadius: 0 }}
            >
              Manage Users
            </Button> */}
            <Button
              onClick={() =>
                this.props.logout(() => {
                  window.location.pathname = '/login';
                })
              }
              className="topMenuButton"
              variant="text"
              style={{ borderTopLeftRadius: 0 }}
            >
              Logout
            </Button>
          </ButtonGroup>
        </div>
        <div style={{ marginBottom: '0.5rem' }}>
          {/* {process.env.REACT_APP_STAGE === 'DEV' && (
            <img
              height={50}
              width={50}
              alt={'dev'}
              src={
                'https://vendor-launch-app-client-dev.s3-us-west-2.amazonaws.com/Dev.png'
              }
            />
          )} */}
          <img
            src={this.props.logo}
            style={{ margin: '0.5rem 1rem', height: '80px' }}
            alt="client logo"
          />
        </div>

        {/* <div
          style={{
            backgroundColor: '#E86613',
            color: 'white',
            borderRadius: '5px',
            height: '40px',
          }}
        >
          <div className="" id="navbarNavDropdown">
            <ul className="customNav">
              {MainPages.map((el, index) => (
                <Link
                  key={index}
                  className="mainNavTab"
                  onClick={() => this.setState({ view: el.view })}
                  to={el.route}
                >
                  <li className="navItem">
                    <div
                      style={this.state.view === el.view ? styles.active : {}}
                      className="navText"
                    >
                      {el.title}
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div> */}
      </>
    );
  }
}
//style={{display: 'flex', flexDirection: 'row', listStyle: 'none'}}
export default withRouter((props) => <AppBar {...props} />);

const MainPages = [
  {
    title: 'Documents',
    route: '/documents',
    view: 'documents',
  },
  // {
  //   title: 'My Account',
  //   route: '/account',
  //   view: "account",
  // }
];
